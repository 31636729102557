import React from "react";
import Img from "../../images/about-landing-image.png";

export default function () {
  return (
    <div className="max-w-screen-lg w-full mx-auto mt-8 my-20">
      <div className="text-center text-3xl sm:text-6xl text-mca-grey font-extrabold">
        Get <span className="text-mca-green">Involved</span>
      </div>

      <div className="w-full flex flex-col-reverse sm:flex-row items-center gap-y-10 sm:gap-y-0 gap-x-20 my-10 px-5">
        <div className="flex-1">
          <div className="text-lg sm:text-2xl text-mca-grey font-extrabold">
            Join as a Member!
          </div>
          <div className="text-mca-grey-2 font-bold text-md sm:text-lg my-2">
            Become a MCA member and participate in initiatives, projects and
            programs. Or just lend a helping hand and some support.
          </div>

          <div>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSf0qewNFlxpEWxUBrly0siVDT-_7bCSMRHScxwEAUhjxaMGWg/viewform?embedded=true"
              height="600"
              frameBorder="0"
              marginHeight={0}
              marginWidth={0}
              className="flex-1 w-full"
            >
              Loading…
            </iframe>
          </div>

          {/*<form className="mt-8 flex flex-col gap-y-4">
            <input
              type="text"
              placeholder="Name*"
              className="w-full bg-mca-grey-3 placeholder:uppercase placeholder:text-[#999] border-none px-5 py-3 font-bold text-mca-grey-2 focus:!ring-[3px] active:!ring-[3px] !ring-mca-green transition"
            />
            <input
              type="email"
              placeholder="Email Address*"
              className="w-full bg-mca-grey-3 placeholder:uppercase placeholder:text-[#999] border-none px-5 py-3 font-bold text-mca-grey-2 focus:!ring-[3px] active:!ring-[3px] !ring-mca-green transition"
            />
            <input
              type="text"
              placeholder="Phone Number*"
              className="w-full bg-mca-grey-3 placeholder:uppercase placeholder:text-[#999] border-none px-5 py-3 font-bold text-mca-grey-2 focus:!ring-[3px] active:!ring-[3px] !ring-mca-green transition"
            />
            <input
              type="text"
              placeholder="Organization Name*"
              className="w-full bg-mca-grey-3 placeholder:uppercase placeholder:text-[#999] border-none px-5 py-3 font-bold text-mca-grey-2 focus:!ring-[3px] active:!ring-[3px] !ring-mca-green transition"
            />
            <input
              type="text"
              placeholder="Organization Website*"
              className="w-full bg-mca-grey-3 placeholder:uppercase placeholder:text-[#999] border-none px-5 py-3 font-bold text-mca-grey-2 focus:!ring-[3px] active:!ring-[3px] !ring-mca-green transition"
            />
            <textarea
              placeholder="Note"
              className="w-full bg-mca-grey-3 placeholder:uppercase placeholder:text-[#999] border-none px-5 py-3 font-bold text-mca-grey-2 focus:!ring-[3px] active:!ring-[3px] !ring-mca-green transition"
            ></textarea>

            <div className="flex gap-x-5 items-center">
              <div>Have you read the Governing Structure of the MCA?</div>
              <div className="flex gap-x-2 items-center text-base uppercase font-bold text-mca-grey-2 cursor-pointer">
                <input
                  type="radio"
                  name="govstruc"
                  value="yes"
                  id="yes"
                  className="text-mca-green cursor-pointer"
                />
                <label for="yes" className="cursor-pointer">
                  Yes
                </label>
              </div>
              <div className="flex gap-x-2 items-center text-lg uppercase font-bold text-mca-grey-2 cursor-pointer">
                <input
                  type="radio"
                  name="govstruc"
                  value="no"
                  id="no"
                  className="text-mca-green cursor-pointer"
                />
                <label for="no" className="cursor-pointer">
                  No
                </label>
              </div>
            </div>

            <div>
              <button className="bg-mca-green text-mca-off-white font-bold py-3 px-5 rounded-full uppercase text-sm !ring-mca-green active:!ring-[3px] focus:!ring-[3px] transition focus:outline-none">
                Join as a Member
              </button>
            </div>
            </form>*/}
        </div>
        <div className="sm:w-1/3">
          <img
            src={Img}
            alt="Misinformation Combat Alliance"
            placeholder="blurred"
            className="w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
}
