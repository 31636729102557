import * as React from "react";
import Footer from "../components/Footer";
import { Donate, JoinAsMember, Partnership } from "../components/GetInvolved";
import Navbar from "../components/Navbar";

const GetInvolvedPage = () => {
  return (
    <div>
      <Navbar />
      <JoinAsMember />
      <Partnership />
      <Donate />
      <Footer />
    </div>
  );
};

export default GetInvolvedPage;
