import React from "react";
import Img from "../../images/about-landing-image.png";

export default function () {
  return (
    <div className="max-w-screen-lg w-full mx-auto mt-8 my-20">
      <div className="w-full flex flex-col sm:flex-row-reverse items-center gap-y-10 sm:gap-y-0 gap-x-20 my-10 px-5">
        <div className="flex-1">
          <div className="text-2xl sm:text-4xl text-mca-grey font-extrabold">
            Explore a Partnership
          </div>
          <div className="text-mca-grey-2 font-bold text-lg sm:text-xl my-5 text-justify">
            Combating misinformation is a collective effort. If you have a
            project or an idea, we're happy to help and become partners. Let's
            work together.
          </div>
          <div>
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSePLoFnuqbBHkm3CEVzhsd-MKGRUV9mAziekAruVa4szXjtiw/viewform?embedded=true"
              height="425"
              frameBorder="0"
              marginHeight={0}
              marginWidth={0}
              className="flex-1 w-full"
            >
              Loading…
            </iframe>
            {/*<a href="https://docs.google.com/forms/d/e/1FAIpQLSePLoFnuqbBHkm3CEVzhsd-MKGRUV9mAziekAruVa4szXjtiw/viewform?usp=sf_link"
                           className="bg-mca-green text-mca-off-white font-bold py-3 px-5 rounded-full uppercase !ring-mca-green active:!ring-[3px] focus:!ring-[3px] transition focus:outline-none">
                            Join as a Partner
                            </a>*/}
          </div>
        </div>
        <div className="sm:w-1/3">
          <img
            src={Img}
            alt="Misinformation Combat Alliance"
            placeholder="blurred"
            className="w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
}
