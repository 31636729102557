import React from "react";
import Img from "../../images/donate-img.png";
import Logos from "../../images/donate-cert-logos.png";

export default function () {
  const [amount, setAmount] = React.useState(10);

  return (
    <div className="max-w-screen-lg w-full mx-auto mt-8 my-20">
      <div className="text-center text-3xl sm:text-6xl text-mca-grey font-extrabold">
        Contribute to the <span className="text-mca-green">MCA!</span>
      </div>

      <div className="w-full flex flex-col sm:flex-row-reverse items-start gap-y-10 sm:gap-y-0 gap-x-20 my-10 px-5">
        {/*<div className="flex-1">
          <div className="text-xl sm:text-3xl text-mca-grey-2 uppercase font-extrabold">
            Contribute now
          </div>

          <form className="mt-4 flex flex-col gap-y-4 my-5">
            <div className="flex gap-x-5 items-center">
              <div className="flex gap-x-2 items-center text-lg uppercase font-bold text-mca-grey-2 cursor-pointer">
                <input
                  type="radio"
                  name="freq"
                  value="one-time"
                  id="one-time"
                  className="text-mca-green cursor-pointer"
                />
                <label for="one-time" className="cursor-pointer">
                  One Time
                </label>
              </div>
              <div className="flex gap-x-2 items-center text-lg uppercase font-bold text-mca-grey-2 cursor-pointer">
                <input
                  type="radio"
                  name="freq"
                  value="monthly"
                  id="monthly"
                  className="text-mca-green cursor-pointer"
                />
                <label for="monthly" className="cursor-pointer">
                  Monthly
                </label>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4 w-full mb-6">
              {[10, 20, 50, 100].map((amt, i) => (
                <div
                  className={
                    "flex items-center justify-center text-2xl sm:text-4xl font-extrabold text-mca-grey-2 py-3 sm:py-6 border-2 border-[#9c9c9c] transition cursor-pointer" +
                    (amount == amt
                      ? " bg-mca-green !border-mca-green text-mca-off-white"
                      : "")
                  }
                  onClick={() => setAmount(amt)}
                >
                  ${amt}
                </div>
              ))}

              <div
                className={
                  "flex items-center justify-center text-2xl sm:text-4xl font-extrabold text-mca-grey-2 border-2 border-[#9c9c9c] transition cursor-pointer col-span-2"
                }
              >
                <div
                  className={
                    "py-3 px-4 sm:p-6 border-r-2 border-[#9c9c9c]" +
                    (![10, 20, 50, 100].includes(amount) || amount === null
                      ? " bg-mca-green !border-mca-green text-mca-off-white"
                      : "")
                  }
                >
                  $
                </div>
                <div className="flex-1">
                  <input
                    type="number"
                    placeholder="Other amount"
                    onChange={(e) => setAmount(e.target.value)}
                    className="w-full border-none py-3 sm:py-6 px-3 text-xl sm:text-3xl font-bold text-mca-grey-2 placeholder:text-[#999]"
                  />
                </div>
              </div>
            </div>
          </form>

          <div>
            <div className="flex items-center gap-x-5">
              <div className="text-lg sm:text-2xl text-mca-grey-2 font-bold">
                Choose Payment Method
              </div>
              <div className="flex items-center text-sm gap-x-1 uppercase">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div>Secure</div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-x-2 my-4">
              <div className="text-mca-off-white rounded-xl py-4 text-center font-extrabold text-md bg-[#643ffc]">
                Visa
              </div>
              <div className="text-mca-off-white rounded-xl py-4 text-center font-extrabold text-md bg-[#0099e5]">
                PayPal
              </div>
            </div>

            <div className="text-mca-grey-2 font-thin text-lg">
              By submitting your payment details to subscribe, you agree to the
              MCA Privacy & Cookies Notice.
            </div>
          </div>
          </div> */}
        <div className="flex-1">
          <div className="text-mca-grey uppercase text-md sm:text-xl font-extrabold">
            We are proudly non-profit
          </div>
          <div className="flex flex-col gap-y-3 font-bold text-mca-grey-2 my-3">
            <p>
              "The Misinformation Combat Alliance Association is not-for-profit
              Section 8 Company under the Companies Act, 2013. The contribution
              you make will support us in the work we do. We are transparent
              about the use of funds and updates are/will be available on the
              website.
            </p>

            <p>
              Disclaimer: Contributions made to the MCA are not (yet) deductible
              under Section 80G"
            </p>
          </div>
          {/*<div>
            <img src={Logos} className="h-32 w-auto" />
            </div> */}
        </div>
        <div className="sm:w-1/3">
          <img
            src={Img}
            alt="Misinformation Combat Alliance"
            className="w-full h-auto block"
          />
          <a
            href="https://instamojo.com/@mcaindia"
            className="text-mca-off-white rounded-xl py-4 text-center font-extrabold text-md bg-[#643ffc]  w-full block mt-5"
          >
            Donate on Instamojo
          </a>
        </div>
      </div>
    </div>
  );
}
